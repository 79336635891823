@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body {
  position: relative;

  margin: 0;
  height: 100%;
  font-family: 'Pretendard-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
  color: #111;
}
ol,
ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

ul {
  padding-left: 0;
  list-style: none;

}

.lnbWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 99px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  box-shadow: -2px 4px 30px 0px rgba(139, 138, 138, 0.25);
}

.textWrap>p:nth-of-type(1) {
  color: #111;
  font-family: Pretendard;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.textWrap .mint {
  color: #00B3BA;
}